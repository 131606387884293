import 'es6-promise/auto';

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

// import initCookieNotice from "./vbcn";
// import { vbcnAdditions, accordions, toTop, animCircle } from "./components/snippets";
// import { accordions, toTop, animCircle } from "./components/snippets";
import { initHeader } from "./components/header";
import { heroSlider } from "./components/sliders";
import { heroControl } from "./components/herocontrol";
// import { gallerygrid } from "./components/gallery";

document.addEventListener('DOMContentLoaded', function() {
    // initCookieNotice();
    // vbcnAdditions();
    // accordions();
    // toTop();
    // animCircle();
    initHeader();
    heroSlider();
    heroControl();
    // gallerygrid();
});
