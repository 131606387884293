export function heroControl() {
    var heroControls = document.querySelectorAll('[data-element="herocontrols"]');
    var heigthControls = document.querySelectorAll('[data-hero-height]');
    var imgControls = document.querySelectorAll('[data-hero-img]');
    var imgSetControls = document.querySelectorAll('[data-img-set]');

    var target = document.getElementById("hero-slide");
    var imgContainer = document.getElementById("hero-img");
    var heroImg = "sample_4-3.jpg";

    var imgFolder = "img-1";

    var heightDisplay = document.getElementById("height-display");

    var heroWrapper = document.getElementById("hero-wrapper");
    var mwSelect = document.getElementById("mw-select");
    var pxInput = document.getElementById("px-input");

    imgControls.forEach(function(element) {
        element.addEventListener('click', function() {
            $(".active-img").removeClass("active-img");
            $(this).addClass("active-img");
            heroImg = element.dataset.heroImg;
            const dataImg = "./"+imgFolder+"/"+heroImg;
            imgContainer.setAttribute("style", `background-image: url('${dataImg}')`);
        });
    });

    imgSetControls.forEach(function(element) {
        element.addEventListener('click', function() {
            $(".active-set").removeClass("active-set");
            $(this).addClass("active-set");
            imgFolder = element.dataset.imgSet;
            const dataImg = "./"+imgFolder+"/"+heroImg;
            imgContainer.setAttribute("style", `background-image: url('${dataImg}')`);
        });
    });

    heigthControls.forEach(function(element) {
        element.addEventListener('click', function() {
            $(".active-height").removeClass("active-height");
            $(this).addClass("active-height");
            const dataHeight = element.dataset.heroHeight;
            target.setAttribute("style", `height: ${dataHeight}`);
            heightDisplay.innerHTML = "Bildh&ouml;he: "+dataHeight;
        });
    });

    mwSelect.addEventListener('change', function () {
        if(this.value == "limited") {
            $("#hero-wrapper").addClass("limited");
        } else {
            $("#hero-wrapper").removeClass("limited");
        }
    });

    pxInput.addEventListener('change', function () {
        if(this.value > 179 && this.value.length < 2001) {
            $(".active-height").removeClass("active-height");
            const dataHeight = this.value+"px";
            target.setAttribute("style", `height: ${dataHeight}`);
            heightDisplay.innerHTML = "Bildh&ouml;he: "+dataHeight;
        } else {
            alert ("bitte einen Wert zwischen 180 und 2000 eingeben, nur Zahlen")
        }
        this.value = "";
    });
}
