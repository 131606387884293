import $ from 'jquery';
import hoverIntent from 'jquery-hoverintent';

var header = $('[data-element="header"]');
var nav = $('[data-element="nav"]');
var navMobile = $('[data-element="nav-mobile"]');
var logo = $('[data-element="logo"]');
var mobileMenuTrigger = $('[data-trigger="menu-mobile"]');
var subMenuTrigger = $('[data-trigger="submenu"]');
var searchTrigger = $('[data-trigger="search"]');

function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth + 'px';
}

function addHeaderTransition() {
    header.css('transition', 'padding 0.2s');
    logo.css('transition', '0.2s');
    logo.find('.logo-icon').css('transition', '0.2s');
}

function removeHeaderTransition() {
    header.css('transition', 'padding 0s');
    logo.css('transition', '0s');
    logo.find('.logo-icon').css('transition', '0s');
}

function openDesktopMenu() {
    $(this).addClass('is-hovered').find('.nav-submenu').fadeIn(200);
}

function closeDesktopMenu() {
    $(this).removeClass('is-hovered').find('.nav-submenu').fadeOut(200);
}

function openMobileMenu() {
    var GSW = getScrollbarWidth();
    // $('html').css({
    //     'overflow': 'hidden',
    //     'margin-left': 'calc(100vw - 100%)',
    //     'margin-right': GSW,
    // });
    mobileMenuTrigger.addClass('is-clicked');
    mobileMenuTrigger.addClass('is-clicked');
    $('.header, .nav-mobile-header, .nav-mobile-list, .nav-mobile-quicklinks, .vbcn').css('margin-right', GSW);
    $('.nav-mobile-inner').show(); // slideDown(400);
}

function closeMobileMenu() {
    // $('html').css({
    //     'overflow-y': 'scroll',
    //     'margin-left': 'auto',
    //     'margin-right': 'auto',
    // });
    mobileMenuTrigger.removeClass('is-clicked');
    $('.header, .nav-mobile-header, .nav-mobile-list, .nav-mobile-quicklinks, .vbcn').css('margin-right', '0px');
    $('.nav-mobile-inner').hide(); // slideUp(400);
}

function mobileMenuLogic() {
    mobileMenuTrigger.hasClass('is-clicked') ? closeMobileMenu() : openMobileMenu();
}

function resizeHeader() {
    if($(window).scrollTop() >= 300) {
        header.addClass('is-scrolled');
        navMobile.addClass('is-scrolled');
    } else {
        header.removeClass('is-scrolled');
        navMobile.removeClass('is-scrolled');
    }
}

function stickyNav() {
    if($(window).scrollTop() >= $(window).height() - nav.height() - 75) {
        nav.addClass('is-fixed');
        header.addClass('no-shadow');
    } else {
        nav.removeClass('is-fixed');
        header.removeClass('no-shadow');
    }
}

function oppositeNav() {
    var a = window.pageYOffset + 100;
    var b = $('.nav').length > 0 ? $('.nav').offset().top - a : 0;
    var subMenu = $('.nav-submenu');
    var highest = 0;

    subMenu.each(function(index, value) {
        if(highest < $(this).height()) {
            highest = $(this).height();
        }
    });

    b >= highest ? nav.addClass('is-opposite') : nav.removeClass('is-opposite');
}

export function initHeader() {

    $(window).on('scroll', addHeaderTransition);
    $(window).on('resize', removeHeaderTransition);
    $(window).on('scroll', resizeHeader);
    $(window).on('scroll', stickyNav);
    $(window).on('scroll', oppositeNav);
    mobileMenuTrigger.on('click', mobileMenuLogic);

    // Desktop menu
    $('.nav-list').hoverIntent({
        over: openDesktopMenu,
        out: closeDesktopMenu,
        selector: '.nav-item',
        sensitivity: 20,
        interval: 20,
        timeout: 5
    });

    // Mobile menu
    subMenuTrigger.on('click', function() {
        if($(this).parent().hasClass('is-clicked')) {
            $(this).parent().removeClass('is-clicked').find('.nav-mobile-submenu').slideUp(300);
        } else {
            $('.nav-mobile-item').removeClass('is-clicked').find('.nav-mobile-submenu').slideUp(300);
            $(this).parent().addClass('is-clicked').find('.nav-mobile-submenu').slideDown(300);
        }
    });

    searchTrigger.on('click', function() {
        $(this).parent().toggleClass('is-opened').find('input').focus();
    })

}
