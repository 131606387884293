import Flickity from 'flickity';
require('flickity-fade');
require('flickity-imagesloaded');

export function heroSlider() {

    var heroSliders = document.querySelectorAll('[data-slider="hero"]');
    heroSliders.forEach(function(el, no) {
        if(el.childElementCount > 1) {
            new Flickity(el, {
                cellSelector: '.hero-slider-slide',
                wrapAround: true,
                cellAlign: 'left',
                autoPlay: 5000,
                imagesLoaded: true,
                fade: true,
                prevNextButtons: false,
                pageDots: true
            });
        }
    });

    var redSliders = document.querySelectorAll('[data-slider="red"]');
    redSliders.forEach(function(el, no) {
        if(el.childElementCount > 1) {
            new Flickity(el, {
                cellSelector: '.redslider-slide',
                wrapAround: true,
                cellAlign: 'left',
                autoPlay: 5000,
                imagesLoaded: true,
                prevNextButtons: false,
                adaptiveHeight: true,
                pageDots: true
            });
        }
    });

    var brownSliders = document.querySelectorAll('[data-slider="brown"]');
    brownSliders.forEach(function(el, no) {
        if(el.childElementCount > 1) {
            new Flickity(el, {
                cellSelector: '.brownslider-slide',
                wrapAround: true,
                cellAlign: 'left',
                autoPlay: 5000,
                imagesLoaded: true,
                prevNextButtons: false,
                pageDots: true
            });
        }
    });

}
